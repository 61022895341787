import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import "./jumbotron.less"
import Net from "../../../images/Net.svg"
import Road from "../../../images/road.svg"
function Jumbotron() {
  return (
    <div>
      <Container className="jumbotron-container">
        <Row>
          <Col>
            <div className="header">
              <h1 className="display-1">The Petrachor Story</h1>
              <p className="h5">Re-imagining the relationship we have with banking</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={7}>
            <p className="h6">
              As entrepreneurs in the payment processing and small business lending space, Petrachor’s founders were
              able to gain years of first-hand experience observing the limitations and inefficiencies of traditional
              finance. They set out on a mission to eliminate waste and inefficiencies in that system to provide a more
              direct path for money to change hands between buyers and sellers of all kinds. That means faster payments,
              minimal fees, confidential transactions, peer-to-peer lending...and any other innovations the community
              develops to meet the needs of a digital future. They began providing Proof of Stake solutions in 2016,
              while ignoring and avoiding the ICO boom that rattled investor confidence and drew regulator attention.
            </p>
          </Col>
          <Col md={5}></Col>
        </Row>
        <Row>
          <Col md={6}>
            <Image src={Road} fluid />
          </Col>
          <Col md={6}>
            <p className="h6">
              Since that time, Petrachor has developed a unique foundation for the future of currency and a platform for
              web3 applications. As the Petrachor network continues to develop with unlimited scalability and
              unsurpassable security, it will converge to an increasingly agreed-upon value, putting it in competition
              with central bank currencies such as the USD, Euro and RMB, at which point the coin’s inherent security,
              decentralization and rules-based supply will be a key differentiator
            </p>
            <p className="h6">
              With libraries built to integrate Petrachor payments with existing infrastructure, peer-to-peer lending
              with a reputation management system, and decentralized applications on the horizon, you'll want to make
              sure you're keeping up to date with the latest developments.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Jumbotron
