import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import EmailForm from "../../emailForm"
import "./GetInTouch.less"

function GetInTouch() {
  return (
    <div className="getintouch-container">
      <Container>
        <Row className="d-flex flex-row align-items-center py-5">
          <Col md={6}>
            <h3 className="h1 text-center">Get In Touch</h3>
            <p className="h6">
              We're more than happy to answer any questions you may have about the Petrachor network.
            </p>
          </Col>
          <Col md={6}>
            <EmailForm />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default GetInTouch
