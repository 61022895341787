import React from "react"
import SEO from "../components/head/seo"
import BackgroundContainer from "../components/homepage/backgroundContainer/backgroundContainer"
import Jumbotron from "../components/aboutus/jumbotron/Jumbotron"
import "./aboutus.less"
import NavigationBar from "../components/navbar/navigationBar"
import ContactButtons from "../components/contact/contactButtons"
import Footer from "../components/footer/footer"
import GetInTouch from "../components/aboutus/getInTouch/GetInTouch"
import Email from "../components/aboutus/email/Email"
import SocialButtons from "../components/aboutus/socialButtons/socialButtons"

function aboutus() {
  return (
    <div>
      <SEO
        title="Petrachor – A Comprehensive Monetary System | About Us "
        desc="Build...Trust...Petrachor was designed to be the platform for those who wish to build the blockchain ecosystem...while possessing the core properties of a monetary system you can trust. "
      ></SEO>
      <div className="about-container">
        <BackgroundContainer>
          <NavigationBar />
          <Jumbotron />
        </BackgroundContainer>
        <div className="social-buttons">
          <SocialButtons/>
        </div>
        <GetInTouch />
        <Footer />
      </div>
    </div>
  )
}

export default aboutus
