import React from "react"
import { Col, Container, Row, Form, Button } from "react-bootstrap"
import "./Email.less"

const Email = () => {
  return (
    <Container className="roadmap-email">
      <Row>
        <Col sm={12}>
          <h5 className="text-center h1">Get updates delivered straight to your inbox</h5>
        </Col>
        <Col>
          <Form>
            <Form.Control type="text" placeholder="Email"></Form.Control>
            <Button className="form-button">Submit</Button>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default Email
