import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Button, Col, Container, Row, Image } from "react-bootstrap"
import "./socialButtons.less"

function SocialButtons() {

  const data = useStaticQuery(graphql`
    query contactButtonsQueryTwo {
      strapiContactUs {
        Header
        Description
        ButtonsHeader
        ButtonsDescription
        ContactButtons {
          id
          Text
          Link
          ColorCode
          Logo {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <Row>
        <Col className="text-center my-5">
          <h2 className="display-2">{data?.strapiContactUs?.ButtonsHeader}</h2>
          <p className="h6">Stay up-to-date with the latest developments. </p>
          <div className="contact-buttons my-5">
            {data.strapiContactUs?.ContactButtons?.map((item, index) => {
              const bgColor = item.ColorCode != "0" ? item.ColorCode : null
              return (
                <a
                  key={item.id}
                  className="btn contact-btn"
                  href={item?.Link}
                  style={{ backgroundColor: `${bgColor}` }}
                >
                  <Image className="mx-2" src={item?.Logo?.localFile.publicURL} />
                  <span style={{ color: `${bgColor}` }}>{item?.Text}</span>
                </a>
              )
            })}
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default SocialButtons
